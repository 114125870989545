<template>
  <div class="container" id="content">
    <div class="title">
      <div class="title-top">
        <div class="el-title-item">
          <el-form ref="form" label-width="80px">
            <el-form-item label="">
              <el-button type="success" size="small" @click="addOption">添加</el-button>
            </el-form-item>
            <el-form-item label="" style="margin-left: 2vw">
              <el-input v-model="searchName" placeholder="请输入设备编号"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <el-button style="margin-left: 1vw;" type="success" size="small" @click="search">搜索</el-button>
      </div>
    </div>
    <el-table
        v-loading="loading"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 1vh;">
      <el-table-column prop="id" label="序号" align="center"></el-table-column>
      <el-table-column prop="name" label="任务名称" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="deviceId" label="水肥机名称" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <div v-for="item in nameList" :key="item.id">
            <div type="text" v-if=" item.id == scope.row.deviceId">{{ item.name }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="deviceNumber" label="设备编号" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="type" label="任务类型" show-overflow-tooltip align="center">
        <template scope="scope">
          <span v-if="scope.row.type==0" style="color: #01b70e">一次性任务</span>
          <span v-if="scope.row.type==1" style="color: #00afff">每年任务</span>
          <span v-if="scope.row.type==2" style="color: #E2A750">每月任务</span>
          <span v-if="scope.row.type==3" style="color: #E2A750">每周任务</span>
          <span v-if="scope.row.type==4" style="color: #E2A750">每日任务</span>
        </template>
      </el-table-column>
      <el-table-column prop="startYear,startMonth, startDay,startWeeks,startHour，startMinute，startSeconds" label="执行时间"
                       show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.startYear }}-{{ scope.row.startMonth }}-{{ scope.row.startDay }} {{
              scope.row.startHour
            }}:{{ scope.row.startMinute }}:{{ scope.row.startSeconds }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="state" label="指令状态" show-overflow-tooltip align="center">
        <template scope="scope">
          <span v-if="scope.row.state==0" style="color: red">等待确认</span>
          <span v-if="scope.row.state==1||scope.row.state==2"><el-switch v-model="scope.row.state" :active-value="1"
                                                                         :inactive-value="2" active-color="#13ce66"
                                                                         inactive-color="#ff4949"
                                                                         @change="changeSwitch(scope.row)"></el-switch></span>
          <span v-if="scope.row.state==3" style="color: #c3c3c3">配置失败</span>
        </template>
      </el-table-column> -->
      <!--      <el-table-column prop="time" label="创建时间" show-overflow-tooltip align="center"></el-table-column>-->
      <el-table-column label="操作" align="center" width="200">
        <template slot-scope="scope">
          <!--          <el-button @click="detail(scope.row)" type="success" size="small">详情</el-button>-->
          <el-button @click="edit(scope.row)" type="success" size="small">编辑</el-button>
          <el-button @click="del(scope.row)" type="success" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block pagingBox">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </div>
    <!--   添加 编辑-->
    <el-dialog title="" :visible.sync="messageBox" width="50vw" class="addAlameBox">
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ formTitle }}</span>
        <span style=" position: absolute; top: 50%; transform: translateY(-50%); right: 0px; font-size: 2.22vh;"></span>
      </div>
      <div class="addAlaForm">
        <div style="display: flex;flex-direction: row;justify-content: space-between">
          <div style="width: 30%">
            <el-table
                ref="multipleTable1"
                :data="nodeData"
                tooltip-effect="dark"
                style="width: 100%;"
                @selection-change="handleSelectionChange">
              <template slot="empty">
                <p>{{ tableText }}</p>
              </template>

              <el-table-column prop="type" label="节点名称" show-overflow-tooltip align="center"></el-table-column>
              <el-table-column type="selection" width="55">
              </el-table-column>
              <!--              <el-table-column prop="data" label="状态" show-overflow-tooltip align="center" >-->
              <!--                <template slot-scope="scope">-->
              <!--                  <el-switch active-color="#13ce66" inactive-color="#ff4949" :active-value="1" :inactive-value="0" v-model="scope.row.data"></el-switch>-->
              <!--                </template>-->
              <!--              </el-table-column>-->
            </el-table>
          </div>
          <div style="width: 66%">
            <el-form ref="addForm" :rules="rules" :model="addForm" label-width="10vw">
              <el-form-item label="任务名称" prop="name">
                <el-input v-model="addForm.name" placeholder="请输入任务名称"></el-input>
              </el-form-item>
              <el-form-item label="水肥机名称" prop="deviceId">
                <el-select v-model="addForm.deviceId" placeholder="请选择水肥机名称" @change="changeName">
                  <el-option
                      v-for="(item,i) in nameList"
                      :key="i"
                      :label="item.deviceName"
                      :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="设备编号" prop="deviceNumber">
                <el-select v-model="addForm.deviceNumber" placeholder="请选择设备编号" @change="changeDeviceNumber">
                  <el-option
                      v-for="(item,i) in numberList"
                      :key="i"
                      :label="item.deviceNumber"
                      :value="item.deviceNumber"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="任务控制" prop="control">
                <el-select v-model="addForm.control" placeholder="请选择">
                  <el-option v-for="(item,i) in controlList" :key="i" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="任务id" prop="taskId" v-if="show">
                <el-select v-model="addForm.taskId" placeholder="请选择">
                  <el-option v-for="(item,i) in taskIdList" :key="i" :label="item.name" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <!--              <el-form-item label="任务类型" prop="taskType">-->
              <!--                <el-select v-model="addForm.taskType" placeholder="请选择" @change="changeType">-->
              <!--                  <el-option v-for="(item,i) in taskTypeList" :key="i" :label="item.name" :value="item.id"></el-option>-->
              <!--                </el-select>-->
              <!--              </el-form-item>-->
              <el-form-item label="开始时间" prop="startTime">
                <el-select v-model="addForm.startYear" placeholder="" class="sel">
                  <el-option v-for="(item,i) in yearList" :key="i" :label="item.name" :value="item.name"></el-option>
                </el-select>
                年
                <el-select v-model="addForm.startMonth" placeholder="" class="sel2">
                  <el-option v-for="(item,i) in monthList" :key="i" :label="item.name" :value="item.name"></el-option>
                </el-select>
                月
                <el-select v-model="addForm.startDay" placeholder="" class="sel2 sel5">
                  <el-option v-for="(item,i) in dayList" :key="i" :label="item.name" :value="item.name"></el-option>
                </el-select>
                日
                <el-select v-model="addForm.startWeeks" placeholder="" class="sel3">
                  <el-option v-for="(item,i) in weekList" :key="i" :label="item.name" :value="item.id"></el-option>
                </el-select>
                周
                <el-select v-model="addForm.startHour" placeholder="" class="sel2 sel5">
                  <el-option v-for="(item,i) in hourList" :key="i" :label="item.name" :value="item.name"></el-option>
                </el-select>
                时
                <el-select v-model="addForm.startMinute" placeholder="" class="sel2 sel5">
                  <el-option v-for="(item,i) in minuteList" :key="i" :label="item.name"
                             :value="item.name"></el-option>
                </el-select>
                分
                <el-select v-model="addForm.startSeconds" placeholder="" class="sel2 sel5">
                  <el-option v-for="(item,i) in secondList" :key="i" :label="item.name"
                             :value="item.name"></el-option>
                </el-select>
                秒
              </el-form-item>
              <el-form-item label="结束时间" prop="endTime">
                <el-select v-model="addForm.endYear" placeholder="" class="sel">
                  <el-option v-for="(item,i) in endYear" :key="i" :label="item.name" :value="item.name"></el-option>
                </el-select>
                年
                <el-select v-model="addForm.endMonth" placeholder="" class="sel2">
                  <el-option v-for="(item,j) in endMonth" :key="j" :label="item.name" :value="item.name"></el-option>
                </el-select>
                月
                <el-select v-model="addForm.endDay" placeholder="" class="sel2 sel5">
                  <el-option v-for="(item,i) in endDay" :key="i" :label="item.name" :value="item.name"></el-option>
                </el-select>
                日
                <el-select v-model="addForm.endWeeks" placeholder="" class="sel3">
                  <el-option v-for="(item,i) in endWeek" :key="i" :label="item.name" :value="item.id"></el-option>
                </el-select>
                周
                <el-select v-model="addForm.endHour" placeholder="" class="sel2 sel5">
                  <el-option v-for="(item,i) in endHour" :key="i" :label="item.name" :value="item.name"></el-option>
                </el-select>
                时
                <el-select v-model="addForm.endMinute" placeholder="" class="sel2 sel5">
                  <el-option v-for="(item,i) in endMinute" :key="i" :label="item.name" :value="item.name"></el-option>
                </el-select>
                分
                <el-select v-model="addForm.endSeconds" placeholder="" class="sel2 sel5">
                  <el-option v-for="(item,i) in endSecond" :key="i" :label="item.name" :value="item.name"></el-option>
                </el-select>
                秒
              </el-form-item>
              <!--          <el-form-item label="指令来源" prop="command">-->
              <!--            <el-input v-model="addForm.command" placeholder="请输入指令来源"></el-input>-->
              <!--                    </el-form-item>-->
              <!--              <el-form-item label="时间" prop="time" v-if="showOnce">-->
              <!--                <el-time-picker-->
              <!--                    v-model="addForm.time"-->
              <!--                    type="time"-->
              <!--                    value-format="HH:mm:ss"-->
              <!--                    placeholder="请选择">-->
              <!--                </el-time-picker>-->
              <!--              </el-form-item>-->
              <!--              <el-form-item label="时间" prop="time" v-if="showDay">-->
              <!--                <el-date-picker-->
              <!--                    v-model="addForm.time"-->
              <!--                    type="datetime"-->
              <!--                    value-format="yyyy-MM-dd HH:mm:ss"-->
              <!--                    placeholder="请选择">-->
              <!--                </el-date-picker>-->
              <!--              </el-form-item>-->
              <!--          <el-form-item label="时间" prop="time" v-show="showMonth">-->
              <!--            <el-date-picker-->
              <!--                v-model="addForm.time"-->
              <!--                type="month"-->
              <!--                value-format="yyyy:MM:dd"-->
              <!--                placeholder="请选择">-->
              <!--            </el-date-picker>-->
              <!--          </el-form-item>-->
            </el-form>
          </div>
        </div>
        <div slot="footer" class="dialog-footer" v-show="showFlag">
          <el-button type="info" @click="messageBox = false">取消</el-button>
          <el-button type="primary" @click="saveForm('addForm')">确定</el-button>
        </div>
      </div>
    </el-dialog>
    <!--    抽屉-->
    <div class="drawerBox">
      <el-drawer title="详情" :visible.sync="drawer" :direction="direction" :before-close="handleClose">
        <div class="drawer">
          <div class="msg">
            <span>任务名称</span><span>{{ this.msgs.taskName }}</span>
          </div>
          <div class="msg">
            <span>设备编号</span><span>{{ this.msgs.deviceNumber }}</span>
          </div>
          <div class="msg">
            <span>任务类型</span>
            <span v-if="this.msgs.taskType==1">一次性任务</span>
            <span v-if="this.msgs.taskType==2">每月任务</span>
            <span v-if="this.msgs.taskType==3">每日任务</span>
          </div>
          <div class="msg">
            <span>状态</span><span>{{ this.msgs.status == '1' ? '启用' : '停用' }}</span>
          </div>
          <div class="msg">
            <span>指令来源</span><span>{{ this.msgs.command }}</span>
          </div>
          <div class="msg">
            <span>时间</span><span>{{ this.msgs.time }}</span>
          </div>
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
export default {
  name: 'triggerManagement',
  data() {
    return {
      value1: true,
      tableData: [],
      tableText: '',
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      ids: 0,
      searchName: '',
      direction: 'rtl',
      drawer: false,
      msgs: [],
      showData: [],
      addForm: {time: null},
      rules: {
        name: [{required: true, message: "任务名称不可为空", trigger: ["blur", "change"]}],
        deviceId: [{required: true, message: "水肥机名称不可为空", trigger: ["blur", "change"]}],
        control: [{required: true, message: "请选择任务控制", trigger: ["blur", "change"]}],
        deviceNumber: [
          {required: true, message: "节点编号不可为空", trigger: ["blur", "change"]},
          // {min: 12, max: 13, message: '设备编号不正确', trigger: ['blur', 'change']},
        ],
        // taskType: [{required: true, message: "任务类型不可为空", trigger: ["blur", "change"]}],
        // startTime: [{required: true, message: "开始时间不可为空", trigger: ["blur", "change"]}],
        // endTime: [{required: true, message: "结束时间不可为空", trigger: ["blur", "change"]}],
        // startYear: [{required: true, message: "请选择任务开始年", trigger: ["blur", "change"]}],
        // startMonth: [{required: true, message: "请选择任务开始月", trigger: ["blur", "change"]}],
        // startDay: [{required: true, message: "请选择任务开始日", trigger: ["blur", "change"]}],
        // startHour: [{required: true, message: "请选择任务时", trigger: ["blur", "change"]}],
        // startMinute: [{required: true, message: "请选择任务开始分", trigger: ["blur", "change"]}],
        // startWeeks: [{required: true, message: "请选择任务开始周", trigger: ["blur", "change"]}],
        // endYear: [{required: true, message: "请选择任务结束年", trigger: ["blur", "change"]}],
        // endMonth: [{required: true, message: "请选择任务结束月", trigger: ["blur", "change"]}],
        // endDay: [{required: true, message: "请选择任务结束日", trigger: ["blur", "change"]}],
        // endMinute: [{required: true, message: "请选择任务结束分", trigger: ["blur", "change"]}],
        // endHour: [{required: true, message: "请选择任务结束时", trigger: ["blur", "change"]}],
        // endSeconds: [{required: true, message: "请选择任务结束秒", trigger: ["blur", "change"]}],
        // endWeeks: [{required: true, message: "请选择任务结束周", trigger: ["blur", "change"]}],

        // command: [{required: true, message: "指令来源不可为空", trigger: ["blur", "change"]}],
      },
      controlList: [{id: '01', name: '开启'}, {id: '00', name: '关闭'}, {id: '10', name: '取反'}],
      // taskTypeList: [{id: '1', name: '一次性任务'}, {id: '2', name: '每月任务'}, {id: '3', name: '每日任务'}],
      yearList: [{id: '0', name: '**'}, {id: '1', name: '2021'}, {id: '2', name: '2022'}, {id: '3', name: '2023'}, {
        id: '4',
        name: '2024'
      }, {id: '5', name: '2025'}, {id: '7', name: '2026'}, {id: '8', name: '2027'}, {id: '9', name: '2028'},],
      monthList: [{id: '0', name: '**'}, {id: '1', name: '1'}, {id: '2', name: '2'}, {id: '3', name: '3'}, {
        id: '4',
        name: '4'
      }, {id: '5', name: '5'}, {id: '6', name: '6'}, {id: '7', name: '7'}, {id: '8', name: '8'}, {
        id: '9',
        name: '9'
      }, {id: '10', name: '10'}, {id: '11', name: '11'}, {id: '12', name: '12'}],
      dayList: [{id: 0, name: "**"}, {id: 1, name: '01'}, {id: 2, name: '02'}, {id: 3, name: '03'}, {
        id: 4,
        name: '04'
      }, {id: 5, name: '05'}, {id: 6, name: '06'}, {id: 7, name: '07'}, {id: 8, name: '08'}, {
        id: 9,
        name: '09'
      }, {id: 10, name: '10'}, {id: 11, name: '11'}, {id: 12, name: '12'}, {id: 13, name: '13'}, {
        id: 14,
        name: '14'
      }, {id: 15, name: '15'}, {id: 16, name: '16'}, {id: 17, name: '17'}, {id: 18, name: '18'}, {
        id: 19,
        name: '19'
      }, {id: 20, name: '20'}, {id: 21, name: '21'}, {id: 22, name: '22'}, {id: 23, name: '23'}, {id: 24, name: '24'}, {
        id: 25,
        name: '25'
      }, {id: 26, name: '26'}, {id: 27, name: '27'}, {id: 28, name: '28'}, {id: 29, name: '29'}, {
        id: 30,
        name: '30'
      }, {id: 31, name: '31'}],
      weekList: [{id: 0, name: '**'}, {id: 1, name: '周一'}, {id: 2, name: '周二'}, {id: 3, name: '周三'}, {
        id: 4,
        name: '周四'
      }, {id: 5, name: '周五'}, {id: 6, name: '周六'}, {id: 7, name: '周日'}],
      hourList: [{id: 0, name: "**"}, {id: 1, name: '01'}, {id: 2, name: '02'}, {id: 3, name: '03'}, {
        id: 4,
        name: '04'
      }, {id: 5, name: '05'}, {id: 6, name: '06'}, {id: 7, name: '07'}, {id: 8, name: '08'}, {
        id: 9,
        name: '09'
      }, {id: 10, name: '10'}, {id: 11, name: '11'}, {id: 12, name: '12'}, {id: 13, name: '13'}, {
        id: 14,
        name: '14'
      }, {id: 15, name: '15'}, {id: 16, name: '16'}, {id: 17, name: '17'}, {id: 18, name: '18'}, {
        id: 19,
        name: '19'
      }, {id: 20, name: '20'}, {id: 21, name: '21'}, {id: 22, name: '22'}, {id: 23, name: '23'}, {id: 24, name: '24'}],
      minuteList: [{id: 0, name: "**"}, {id: 1, name: '00'}, {id: 1, name: '01'}, {id: 2, name: '02'}, {
        id: 3,
        name: '03'
      }, {
        id: 4,
        name: '04'
      }, {id: 5, name: '05'}, {id: 6, name: '06'}, {id: 7, name: '07'}, {id: 8, name: '08'}, {
        id: 9,
        name: '09'
      }, {id: 10, name: '10'}, {id: 11, name: '11'}, {id: 12, name: '12'}, {id: 13, name: '13'}, {
        id: 14,
        name: '14'
      }, {id: 15, name: '15'}, {id: 16, name: '16'}, {id: 17, name: '17'}, {id: 18, name: '18'}, {
        id: 19,
        name: '19'
      }, {id: 20, name: '20'}, {id: 21, name: '21'}, {id: 22, name: '22'}, {id: 23, name: '23'}, {id: 24, name: '24'}, {
        id: 25,
        name: '25'
      }, {id: 26, name: '26'}, {id: 27, name: '27'}, {id: 28, name: '28'}, {id: 29, name: '29'}, {
        id: 30,
        name: '30'
      }, {id: 31, name: '31'}, {id: 32, name: "32"}, {id: 33, name: '33'}, {id: 34, name: '34'}, {id: 35, name: '35'}, {
        id: 36,
        name: '36'
      }, {id: 37, name: '37'}, {id: 38, name: '38'}, {id: 39, name: '39'}, {id: 40, name: '40'}, {
        id: 41,
        name: '41'
      }, {id: 42, name: '42'}, {id: 43, name: '43'}, {id: 44, name: '44'}, {id: 45, name: '45'}, {
        id: 46,
        name: '46'
      }, {id: 47, name: '47'}, {id: 48, name: '48'}, {id: 49, name: '49'}, {id: 50, name: '50'}, {
        id: 51,
        name: '51'
      }, {id: 52, name: '52'}, {id: 53, name: '53'}, {id: 54, name: '54'}, {id: 55, name: '55'}, {id: 56, name: '56'}, {
        id: 57,
        name: '57'
      }, {id: 58, name: '58'}, {id: 59, name: '59'}, {id: 60, name: '60'}],
      secondList: [{id: 0, name: "**"}, {id: 1, name: '00'}, {id: 1, name: '01'}, {id: 2, name: '02'}, {
        id: 3,
        name: '03'
      }, {
        id: 4,
        name: '04'
      }, {id: 5, name: '05'}, {id: 6, name: '06'}, {id: 7, name: '07'}, {id: 8, name: '08'}, {
        id: 9,
        name: '09'
      }, {id: 10, name: '10'}, {id: 11, name: '11'}, {id: 12, name: '12'}, {id: 13, name: '13'}, {
        id: 14,
        name: '14'
      }, {id: 15, name: '15'}, {id: 16, name: '16'}, {id: 17, name: '17'}, {id: 18, name: '18'}, {
        id: 19,
        name: '19'
      }, {id: 20, name: '20'}, {id: 21, name: '21'}, {id: 22, name: '22'}, {id: 23, name: '23'}, {id: 24, name: '24'}, {
        id: 25,
        name: '25'
      }, {id: 26, name: '26'}, {id: 27, name: '27'}, {id: 28, name: '28'}, {id: 29, name: '29'}, {
        id: 30,
        name: '30'
      }, {id: 31, name: '31'}, {id: 32, name: "32"}, {id: 33, name: '33'}, {id: 34, name: '34'}, {id: 35, name: '35'}, {
        id: 36,
        name: '36'
      }, {id: 37, name: '37'}, {id: 38, name: '38'}, {id: 39, name: '39'}, {id: 40, name: '40'}, {
        id: 41,
        name: '41'
      }, {id: 42, name: '42'}, {id: 43, name: '43'}, {id: 44, name: '44'}, {id: 45, name: '45'}, {
        id: 46,
        name: '46'
      }, {id: 47, name: '47'}, {id: 48, name: '48'}, {id: 49, name: '49'}, {id: 50, name: '50'}, {
        id: 51,
        name: '51'
      }, {id: 52, name: '52'}, {id: 53, name: '53'}, {id: 54, name: '54'}, {id: 55, name: '55'}, {id: 56, name: '56'}, {
        id: 57,
        name: '57'
      }, {id: 58, name: '58'}, {id: 59, name: '59'}, {id: 60, name: '60'}],
      endYear: [{id: '0', name: '**'}, {id: '1', name: '2021'}, {id: '2', name: '2022'}, {id: '3', name: '2023'}, {
        id: '4',
        name: '2024'
      }, {id: '5', name: '2025'}, {id: '7', name: '2026'}, {id: '8', name: '2027'}, {id: '9', name: '2028'},],
      endMonth: [{id: '0', name: '**'}, {id: '1', name: '1'}, {id: '2', name: '2'}, {id: '3', name: '3'}, {
        id: '4',
        name: '4'
      }, {id: '5', name: '5'}, {id: '6', name: '6'}, {id: '7', name: '7'}, {id: '8', name: '8'}, {
        id: '9',
        name: '9'
      }, {id: '10', name: '10'}, {id: '11', name: '11'}, {id: '12', name: '12'}],
      endDay: [{id: 0, name: "**"}, {id: 1, name: '01'}, {id: 2, name: '02'}, {id: 3, name: '03'}, {
        id: 4,
        name: '04'
      }, {id: 5, name: '05'}, {id: 6, name: '06'}, {id: 7, name: '07'}, {id: 8, name: '08'}, {
        id: 9,
        name: '09'
      }, {id: 10, name: '10'}, {id: 11, name: '11'}, {id: 12, name: '12'}, {id: 13, name: '13'}, {
        id: 14,
        name: '14'
      }, {id: 15, name: '15'}, {id: 16, name: '16'}, {id: 17, name: '17'}, {id: 18, name: '18'}, {
        id: 19,
        name: '19'
      }, {id: 20, name: '20'}, {id: 21, name: '21'}, {id: 22, name: '22'}, {id: 23, name: '23'}, {id: 24, name: '24'}, {
        id: 25,
        name: '25'
      }, {id: 26, name: '26'}, {id: 27, name: '27'}, {id: 28, name: '28'}, {id: 29, name: '29'}, {
        id: 30,
        name: '30'
      }, {id: 31, name: '31'}],
      endWeek: [{id: 0, name: '**'}, {id: 1, name: '周一'}, {id: 2, name: '周二'}, {id: 3, name: '周三'}, {
        id: 4,
        name: '周四'
      }, {id: 5, name: '周五'}, {id: 6, name: '周六'}, {id: 7, name: '周日'}],
      endHour: [{id: 0, name: "**"}, {id: 1, name: '01'}, {id: 2, name: '02'}, {id: 3, name: '03'}, {
        id: 4,
        name: '04'
      }, {id: 5, name: '05'}, {id: 6, name: '06'}, {id: 7, name: '07'}, {id: 8, name: '08'}, {
        id: 9,
        name: '09'
      }, {id: 10, name: '10'}, {id: 11, name: '11'}, {id: 12, name: '12'}, {id: 13, name: '13'}, {
        id: 14,
        name: '14'
      }, {id: 15, name: '15'}, {id: 16, name: '16'}, {id: 17, name: '17'}, {id: 18, name: '18'}, {
        id: 19,
        name: '19'
      }, {id: 20, name: '20'}, {id: 21, name: '21'}, {id: 22, name: '22'}, {id: 23, name: '23'}, {id: 24, name: '24'}],
      endMinute: [{id: 0, name: "**"}, {id: 1, name: '00'}, {id: 1, name: '01'}, {id: 2, name: '02'}, {
        id: 3,
        name: '03'
      }, {
        id: 4,
        name: '04'
      }, {id: 5, name: '05'}, {id: 6, name: '06'}, {id: 7, name: '07'}, {id: 8, name: '08'}, {
        id: 9,
        name: '09'
      }, {id: 10, name: '10'}, {id: 11, name: '11'}, {id: 12, name: '12'}, {id: 13, name: '13'}, {
        id: 14,
        name: '14'
      }, {id: 15, name: '15'}, {id: 16, name: '16'}, {id: 17, name: '17'}, {id: 18, name: '18'}, {
        id: 19,
        name: '19'
      }, {id: 20, name: '20'}, {id: 21, name: '21'}, {id: 22, name: '22'}, {id: 23, name: '23'}, {id: 24, name: '24'}, {
        id: 25,
        name: '25'
      }, {id: 26, name: '26'}, {id: 27, name: '27'}, {id: 28, name: '28'}, {id: 29, name: '29'}, {
        id: 30,
        name: '30'
      }, {id: 31, name: '31'}, {id: 32, name: "32"}, {id: 33, name: '33'}, {id: 34, name: '34'}, {id: 35, name: '35'}, {
        id: 36,
        name: '36'
      }, {id: 37, name: '37'}, {id: 38, name: '38'}, {id: 39, name: '39'}, {id: 40, name: '40'}, {
        id: 41,
        name: '41'
      }, {id: 42, name: '42'}, {id: 43, name: '43'}, {id: 44, name: '44'}, {id: 45, name: '45'}, {
        id: 46,
        name: '46'
      }, {id: 47, name: '47'}, {id: 48, name: '48'}, {id: 49, name: '49'}, {id: 50, name: '50'}, {
        id: 51,
        name: '51'
      }, {id: 52, name: '52'}, {id: 53, name: '53'}, {id: 54, name: '54'}, {id: 55, name: '55'}, {id: 56, name: '56'}, {
        id: 57,
        name: '57'
      }, {id: 58, name: '58'}, {id: 59, name: '59'}, {id: 60, name: '60'}],
      endSecond: [{id: 0, name: "**"}, {id: 1, name: '00'}, {id: 1, name: '01'}, {id: 2, name: '02'}, {
        id: 3,
        name: '03'
      }, {
        id: 4,
        name: '04'
      }, {id: 5, name: '05'}, {id: 6, name: '06'}, {id: 7, name: '07'}, {id: 8, name: '08'}, {
        id: 9,
        name: '09'
      }, {id: 10, name: '10'}, {id: 11, name: '11'}, {id: 12, name: '12'}, {id: 13, name: '13'}, {
        id: 14,
        name: '14'
      }, {id: 15, name: '15'}, {id: 16, name: '16'}, {id: 17, name: '17'}, {id: 18, name: '18'}, {
        id: 19,
        name: '19'
      }, {id: 20, name: '20'}, {id: 21, name: '21'}, {id: 22, name: '22'}, {id: 23, name: '23'}, {id: 24, name: '24'}, {
        id: 25,
        name: '25'
      }, {id: 26, name: '26'}, {id: 27, name: '27'}, {id: 28, name: '28'}, {id: 29, name: '29'}, {
        id: 30,
        name: '30'
      }, {id: 31, name: '31'}, {id: 32, name: "32"}, {id: 33, name: '33'}, {id: 34, name: '34'}, {id: 35, name: '35'}, {
        id: 36,
        name: '36'
      }, {id: 37, name: '37'}, {id: 38, name: '38'}, {id: 39, name: '39'}, {id: 40, name: '40'}, {
        id: 41,
        name: '41'
      }, {id: 42, name: '42'}, {id: 43, name: '43'}, {id: 44, name: '44'}, {id: 45, name: '45'}, {
        id: 46,
        name: '46'
      }, {id: 47, name: '47'}, {id: 48, name: '48'}, {id: 49, name: '49'}, {id: 50, name: '50'}, {
        id: 51,
        name: '51'
      }, {id: 52, name: '52'}, {id: 53, name: '53'}, {id: 54, name: '54'}, {id: 55, name: '55'}, {id: 56, name: '56'}, {
        id: 57,
        name: '57'
      }, {id: 58, name: '58'}, {id: 59, name: '59'}, {id: 60, name: '60'}],
      formTitle: "",
      messageBox: false,
      showFlag: false,
      value: true,
      nameList: [],
      nodeData: [{type: '', data: ''}],
      numberList: [],
      multipleSelection: [],
      show: false,
      taskIdList: [],
      isTask: true,
      // showOnce: true,
      // showDay: false,
      // showMonth:false,
      // pickerOptions: {
      //   shortcuts: [{
      //     text: '今天',
      //     onClick(picker) {
      //       picker.$emit('pick', new Date());
      //     }
      //   }, {
      //     text: '昨天',
      //     onClick(picker) {
      //       const date = new Date();
      //       date.setTime(date.getTime() - 3600 * 1000 * 24);
      //       picker.$emit('pick', date);
      //     }
      //   }, {
      //     text: '一周前',
      //     onClick(picker) {
      //       const date = new Date();
      //       date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
      //       picker.$emit('pick', date);
      //     }
      //   }]
      // },

    };
  },
  mounted() {
    this.getList();
    this.getDevice();
  },
  // created() {
  //   this.$nextTick(()=>{
  //     for (let i = 0; i < this.nodeData.length;i++){
  //       this.$refs.fileTable.toggleRowSelection(this.fileUploadData[i])
  //     }
  //
  //   })
  //
  // },
  methods: {
// 获取水肥机
    getDevice() {
      this.$post('/deviceManage/getDevicesList', {
        page: this.page,
        limit: this.size,
      }).then((res) => {
        if (res.data.state == "success") {
          this.nameList = res.data.datas;
          //console.log('nameList', this.nameList);
        }
      })
    },
    //请求列表数据
    getList() {
      this.$post("/relay/getTaskList", {
        page: this.page,
        size: this.size,
        deviceNumber: this.searchName
      }).then((res) => {
        if (res.data.state == "success") {
          this.tableData = res.data.datas;
          this.total = res.data.count;
          //console.log("获取列表", this.tableData)
        }
        setTimeout(() => {
          // this.loading = false;
        }, 500);
      });
    },
    // changeTime(){
    //   this.addForm.time == null;
    // },
    // 节点列表多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
      //console.log('选择----', val)
      // let selectLength = val.length;
      // //console.log('selectLength',selectLength)
    },
    //确认提交
    saveForm(formName) {
      debugger;
      if(!this.isTask){
        return false;
      }
      this.isTask = false;
      let id;
      let url;
      let taskId;
      if (this.formTitle == '新增') {
        url = '/relay/sendTask';
      } else if (this.formTitle == '编辑') {
        url = '/relay/sendTask';
        id = this.ids;
        taskId = this.taskId;
        let context = this.addForm.control;
        //console.log('context', context)
        if (context == '开启') {
          this.addForm.control = '01'
        } else if (context == '关闭') {
          this.addForm.control = '00'
        } else {
          this.addForm.control = '10'
        }
      }
      // 截取年份后两位
      if (this.addForm.startYear){
        var startYear = this.addForm.startYear.substring(this.addForm.startYear.length - 2);
        //console.log('startYear', startYear)
      }

     if (this.addForm.endYear){
       var endYear = this.addForm.endYear.substring(this.addForm.endYear.length - 2);
     }
      let control = this.addForm.control + "11" + "0000";
      let enabled = '';

      for (let i = 0; i < 16; i++) {
        var isChecked = false;
        for (let j = 0; j < this.multipleSelection.length; j++) {
          if (this.multipleSelection[j].no == i) {
            enabled += '1';
            isChecked = true;
          }
        }
        if (!isChecked) {
          enabled += '0';
        }
      }
      enabled = enabled.split('').reverse().join('')
      if (this.addForm.startYear ==''||this.addForm.startYear==null){
        this.$message.info('请选择开始年')
        return false
      } else if(this.addForm.startMonth==''||this.addForm.startMonth==null){
        this.$message.info('请选择开始月')
        return false
      }else if(this.addForm.startDay==''||this.addForm.startDay==null){
        this.$message.info('请选择开始日')
        return false
      }else if(this.addForm.startWeeks==''||this.addForm.startWeeks==null){
        this.$message.info('请选择开始周')
        return false
      }else if(this.addForm.startHour==''||this.addForm.startHour==null){
        this.$message.info('请选择开始时')
        return false
      }else if(this.addForm.startMinute==''||this.addForm.startMinute==null){
        this.$message.info('请选择开始分')
        return false
      }else if(this.addForm.startSeconds==''||this.addForm.startSeconds==null){
        this.$message.info('请选择开始杪')
        return false
      }else if(this.addForm.endYear==''||this.addForm.endYear==null){
        this.$message.info('请选择结束年')
        return false
      }else if(this.addForm.endMonth==''||this.addForm.endMonth==null){
        this.$message.info('请选择结束月')
        return false
      }else if(this.addForm.endDay==''||this.addForm.endDay==null){
        this.$message.info('请选择结束日')
        return false
      }else if(this.addForm.endWeeks==''||this.addForm.endWeeks==null){
        this.$message.info('请选择结束周')
        return false
      }else if(this.addForm.endHour==''||this.addForm.endHour==null){
        this.$message.info('请选择结束时')
        return false
      }else if(this.addForm.endMinute==''||this.addForm.endMinute==null){
        this.$message.info('请选择结束分')
        return false
      }else if(this.addForm.endSeconds==''||this.addForm.endSeconds==null) {
        this.$message.info('请选择结束杪')
        return false
      }
      if (this.addForm.deviceNumber){
        if (this.multipleSelection.length < 1) {
          //console.log('请选择节点编号')
          this.$message.info('请选择节点编号')
          return false
        }
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post(url, {
            deviceNumber: this.addForm.deviceNumber,        //设备号
            name: this.addForm.name,                //任务名称
            taskId: taskId, //任务id
            deviceId: this.addForm.deviceId,
            enabled: enabled,             //使能位16bin
            control: control,             //控制字节16bin
            startYear: startYear,           //开始动作的年
            startMonth: this.addForm.startMonth,          //开始动作的月
            startDay: this.addForm.startDay,            //开始动作的日
            startHour: this.addForm.startHour,           //开始动作的时
            startMinute: this.addForm.startMinute,         //开始动作的分
            startSeconds: this.addForm.startSeconds,        //开始动作的秒
            startWeeks: this.addForm.startWeeks,          //开始动作的周
            endYear: endYear,                  //结束动作的年
            endMonth: this.addForm.endMonth,            //结束动作的月
            endDay: this.addForm.endDay,
            endHour: this.addForm.endHour,                    //结束动作的时
            endMinute: this.addForm.endMinute,           //结束动作的分
            endSeconds: this.addForm.endSeconds,          //结束动作的秒
            endWeeks: this.addForm.endWeeks,
          }).then((res) => {
            if (res.data.state == 'success') {
              this.$message.success('操作成功')
              this.messageBox = false
              this.getList();
            } else {
              this.$message.error(res.data.msg)
              this.messageBox = false
            }
            setTimeout(() => {
              this.isTask = true;
            }, 1000)
          })
        } else {
          //console.log('error submit!!');
          setTimeout(() => {
              this.isTask = true;
          }, 1000)
          return false;
        }
      });
    },
    // 新增
    addOption() {
      this.formTitle = "新增";
      this.messageBox = true;
      this.showFlag = true;
      // this.addForm.taskName = ''
      // this.addForm.deviceNumber = ''
      // this.addForm.command = ''
      // this.addForm.taskType = ''
      this.$nextTick(() => {
        this.addForm = {};
        this.$refs.addForm.resetFields();
      });
    },
    //编辑
    edit(item) {
      //console.log('item', item)
      this.ids = item.id;
      this.taskId = item.taskId;
      this.showFlag = true;
      this.formTitle = "编辑";
      this.messageBox = true;
      // this.control = item.control;
      // if (item.taskType == '1') {
      //   this.showOnce = true;
      //   this.showDay = false;
      // } else {
      //   this.showOnce = false;
      //   this.showDay = true;
      // }
      // this.addForm.time = item.time
      this.getNodeData(item.deviceNumber);
      let con = item.control.substring(0, 2)
      //console.log('前两位', con)
      if (con == '01') {
        //console.log('01010101')
        item.control = '开启'
      } else if (con == '00') {
        //console.log('000000')
        item.control = '关闭'
      } else {
        //console.log('101010')
        item.control = '取反'
      }
      // 16字节再次反转
      let enId = item.enabled.substring(item.enabled.length - 4);
      //console.log('enId', enId)
      let reEnid = enId.split('').reverse()
      //console.log('reEnid',reEnid)
      for(let j = 0; j<reEnid.length; j++){
        //console.log('reEnid[j]',reEnid[j])
         if (reEnid[j]=='1'){
           //console.log('等于11111了')
           // this.$nextTick(()=>{
             // this.$refs.multipleTable1.toggleRowSelection(this.nodeData[j])
           // })
           setTimeout(() => {
             this.$refs.multipleTable1.toggleRowSelection(this.nodeData[j])
           }, 500)
         }else{
           //console.log('等于0000了')
 // this.multipleTable1.clearSelection();
         }

      }
      this.$nextTick(() => {
        // this.addForm.deviceName = item.deviceName
        //this.addForm = JSON.parse(JSON.stringify(item));
        this.addForm = item;
        this.addForm.deviceId = parseInt(item.deviceId);
        // if (item.taskType == 1) {
        //   this.addForm.time = item.time
        //   //  this.addForm.time ='12:00:00'
        //   //console.log(this.addForm.time, item.taskType)
        // } else {
        //   this.addForm.time = item.year + '-' + item.month + '-' + item.day + ' ' + item.time
        //   //  this.addForm.time = '2021-12-23 12:00:00'
        //   //console.log(this.addForm.time, item.taskType)
        // }
        //console.log('i:', item)
         // this.getNodeData(item.deviceNumber)
      });
    },

// 删除
    del(item) {
      //console.log(item)
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$get("/relay/deleteTask", {
          taskId: item.taskId,
          deviceNumber: item.deviceNumber
        }).then((res) => {
          if (res.data.state == "success") {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.info(res.data.msg);
          }
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
    },
// 搜索
    search() {
      //console.log("搜索", this.searchName)
      this.page = 1
      this.getList();
    },
    // 任务类型下拉监听
    // changeType(val) {
    //   //console.log('任务类型下拉监听', val)
    //   let data = val;
    //   this.deviceType = data
    //   if (val == 1) {
    //     //console.log("1")
    //     this.showOnce = true;
    //     this.showDay = false;
    //     this.$set(this.addForm, 'time', '00:00:00')
    //     // this.showMonth = false;
    //   } else if (val == 2) {
    //     // this.showMonth = true;
    //     this.showOnce = false;
    //     this.showDay = true;
    //     //console.log("2")
    //     this.$set(this.addForm, 'time', '0000-00-00 00:00:00')
    //   } else if (val == 3) {
    //     //console.log("3")
    //     this.showDay = true;
    //     this.showOnce = false;
    //     this.$set(this.addForm, 'time', '0000-00-00 00:00:00')
    //     // this.showMonth = false;
    //   }
    // },
    // 水肥机监听
    changeName(val) {
      //console.log('水肥名称下拉监听', val)
      let data = val
      this.deviceName = data;
      // //console.log('水肥机监听',data)
      this.getDevices(data)
    },
    // 获取水肥机下的所有继电器设备号
    getDevices(id) {
      this.$post('/relay/relayDeviceList', {
        deviceId: id
      }).then((res) => {
        if (res.data.state == 'success') {
          if (res.data.datas) {
            this.numberList = res.data.datas;
          }
        }
      })
    },
    // 设备号监听
    changeDeviceNumber(val) {
      //console.log('设备号监听', val)
      this.getNodeData(val)
      // this.getTaskids(val)
    },

    // 获取添加页面左侧节点信息
    getNodeData(num) {
      this.$post('/relay/detail', {
        deviceNumber: num
      }).then((res) => {
        if (res.data.state == 'success') {
          //console.log("获取添加页面左侧节点信息",res)
          if (res.data.data) {
            if (res.data.data.type) {
              let types = res.data.data.type.replaceAll(/\s/g, "").split("/");
              let datas = res.data.data.data.replaceAll(/\s/g, "").split("|");
              //console.log("type", types)
              var nodeArr = [];
              for (let i = 0; i < types.length; i++) {
                nodeArr.push({no: i, type: types[i], data: datas[i]})
              }
              //console.log("nodeArr", nodeArr)
              this.nodeData = nodeArr;
              // for (let j = 0; j<datas.length; j++){
              //   this.nodeData[j].data = datas[j] == '开'?1:0
              // }
            } else {
              //console.log("没有节点---")
              this.tableText = '暂无数据'
              this.nodeData = []
            }
          }
        }
      })
    },
    // 开关回调
    changeSwitch(f) {
      //console.log('开关回调', f)
      let state = f.state;
      let enable;
      if (state == 1) {
        enable = 1
      } else if (state == 2) {
        enable = 0
      }
      this.$post('/relay/disableTask', {
        deviceNumber: f.deviceNumber,
        enable: enable,  // 1启用 0禁用
        taskId: f.taskId
      }).then((res) => {
        if (res.data.state == 'success') {
          this.$message.info(res.data.msg)
          //console.log("kaikaikai")
          this.getList();
        }
      })
    },

    handleClose() {
      this.drawer = false;
    },
    handleCloses() {
      this.messageBox = false;
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
  },
};
</script>

<style scoped lang="less">
@import "../../../style/backTable.less";
</style>
<style scoped>
#content /deep/ .el-table ::before {
  z-index: inherit;
}

#content /deep/ .el-table th {
  background-color: rgba(240, 242, 245, 1) !important;
}

#content /deep/ .el-table {
  max-height: 78vh;
  overflow-y: scroll;

}

#content /deep/ .el-table::-webkit-scrollbar {
  display: none;
}

.addAlaForm /deep/ .el-select {
  width: 100% !important;
}

.addAlaForm /deep/ .sel {
  width: 5.7vw !important;
}

.addAlaForm /deep/ .sel1 {
  width: 4vw !important;
}

.addAlaForm /deep/ .sel2 {
  width: 4.5vw !important;
}

.addAlaForm /deep/ .sel5 {
  margin-top: 3px !important;
}

.addAlaForm /deep/ .sel3 {
  width: 5.5vw !important;
}

.dialog-footer {
  height: 5vh;
  text-align: right;
}

.dialog-footer /deep/ .el-button, .el-button--info {
  margin-left: 10px !important;

}

.dialog-footer /deep/ .el-button--info {
  background-color: transparent !important;
  color: #000;
}

.title /deep/ .el-input__inner {
  height: 33px !important;
}

.drawerBox /deep/ .msg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1vh 1vw;
}
</style>

